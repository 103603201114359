import React, { useEffect, useState } from 'react';

import Box from './components/box'
import Header from './components/header'

import './components/layout.scss'
import layout from './components/layout.module.scss'

function App(props) {
  const [data, setData] = useState([])
  useEffect(() => {
    async function fetchData() {
      const d = await fetch('/data.json').then(d => d.json())
      setData(d)
    }
    fetchData();
  }, [])

  // const displayData = data.filter(d => d && !!d.title).map( (d,x) => (
  const displayData = data.map( (d,x) => (
    <Box title={d.title} url={d.href} key={`box-${x}`}>
      <ul>
        { d.items.map( (i,y) => (
          <li key={`li-${y}`}>
            { (i.href && <a target="_blank" rel="noopener noreferrer" href={i.href}>{i.title}</a> )|| <>{i.title}</> }
            { i.comments && false && <> | comments </>}
          </li>
        )) }
      </ul>
    </Box>
  ))
  return (
    <div className={layout.body}>
    <Header />
    <main>
      <div className="container">
        { displayData }
      </div>
    </main>
    <footer>
      <div className="container">
        <div className={layout.footer}>
          <p>13S7GekXkv4jb28h45PRBq8AojAop8LQuM</p>
          <small>&copy;{new Date().getFullYear()}</small>
        </div>
      </div>
    </footer>
  </div>
  );
}

export default App;
